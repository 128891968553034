import React from 'react';
import { UserType } from '../Types';
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Divider,
    Paper,
    Box,
    CardActions,
    Button,
} from '@mui/material';

interface UserListProps {
    users: UserType[];
    selectedUser: string;
    onUserSelect: (userId: string) => void;
    handleLoadMore: () => void;
}

export const UserList: React.FC<UserListProps> = ({ users, selectedUser, onUserSelect, handleLoadMore }) => {
  return (
        <Box>
            <Typography variant="h6" align="center" gutterBottom>
                Users
            </Typography>
            <Divider />
            <Paper elevation={3} sx={{ flexGrow: 1 }}>
                <Box sx={{ height: '100%', overflow: 'auto' }}>
                    <List>
                        {users.map((user) => (
                            <ListItem
                                key={user.email}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: 'action.hover',
                                    },
                                    backgroundColor: selectedUser === user.email ? 'action.selected' : 'inherit',
                                }}
                                onClick={() => onUserSelect(user.email)}
                            >
                                <ListItemText
                                    primary={
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            {`${user.first_name} ${user.last_name}`}
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Typography component="span" display="block">
                                                {user.email}
                                            </Typography>
                                            <Typography component="span" variant="caption">
                                                Last active: {new Date(user.most_recent_session_time).toLocaleString()}
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Paper>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLoadMore}
                    >
                        Load More
                    </Button>
                </CardActions>
            </Box>
        </Box>
    );
};