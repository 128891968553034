import React, { useState, useCallback } from "react";
import Box from "@mui/material/Box";
import { BarChart } from "@mui/x-charts/BarChart";
import { Typography, TextField, Button, ButtonGroup, useTheme } from "@mui/material";
import { getUsageGraph, getUserProtocolAnalytics } from "../../api/helpers";
import { tokens } from "../../Theme";

const chartSetting = {
  width: 1000,
  height: 500,
};

const valueFormatter = (value: number | null) => `${value}`;

export function SearchUserUsage() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [email, setEmail] = useState("");
  const [chartData, setChartData] = useState<{ screen: string; count: number }[]>([]);
  const [originalData, setOriginalData] = useState<{ screen: string; count: number }[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [viewMode, setViewMode] = useState<"all" | "daily">("all"); 
  const [dataFetched, setDataFetched] = useState(false);

  // Fetch Usage Data
  const fetchData = useCallback(async () => {
    try {
      setDataFetched(false);
      setErrorMessage("");

      const response = await getUsageGraph(email);
      const usageData = response?.usages || [];

      if (usageData.length === 0) {
        setChartData([]);
        setOriginalData([]);
        setErrorMessage("No usage data found in the last 30 days! Please check the email or try again.");
        return;
      }

      const screenCounts: { [key: string]: number } = {};

      usageData.forEach((usage: any) => {
        const screenName = usage.screen_name;
        if (screenCounts[screenName]) {
          screenCounts[screenName]++;
        } else {
          screenCounts[screenName] = 1;
        }
      });

      const data = Object.keys(screenCounts).map((screen) => ({
        screen,
        count: screenCounts[screen],
      }));

      setChartData(data);
      setOriginalData(data); 
      setDataFetched(true);
    } catch (error) {
      console.error("Error fetching usage data:", error);
      setErrorMessage("An error occurred while fetching data.");
    }
  }, [email]);

  // Fetch Daily Info Data
  const fetchDailyInfoData = useCallback(async () => {
    try {
      setErrorMessage("");
      const response = await getUserProtocolAnalytics("this_month", email);
      
      if (!response) {
        setErrorMessage("No protocol data found for this user.");
        return;
      }

      const dailyData = [
        { screen: "nutrition", count: response.nutrition },
        { screen: "recovery", count: response.recovery },
        { screen: "movement", count: response.movement },
        { screen: "cognition", count: response.cognition },
        { screen: "connection", count: response.connection },
        { screen: "aesthetics", count: response.aesthetics },
      ];

      setChartData(dailyData);
    } catch (error) {
      console.error("Error fetching daily info data:", error);
      setErrorMessage("An error occurred while fetching daily info data.");
    }
  }, [email]);

  const handleSearch = () => {
    setViewMode("all");
    fetchData();
  };

  const handleViewModeChange = async (newViewMode: "all" | "daily") => {
    setViewMode(newViewMode);
    if (newViewMode === "daily") {
      fetchDailyInfoData();
    } else {
      setChartData(originalData);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography variant="h3" align="center" gutterBottom>
        SEARCH SCREEN USAGE OF A USER BY EMAIL
      </Typography>
      <Typography variant="h3" align="center" gutterBottom>
        (Last 30 Days)
      </Typography>
      <Box sx={{ display: "flex", gap: 2, mb: 2, mt: 2 }}>
        <TextField 
          label="Enter Email" 
          variant="outlined" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
        />
        <Button 
          variant="contained" 
          onClick={handleSearch} 
          sx={{
            backgroundColor: colors.greenAccent[500],
            color: colors.grey[100],
            "&:hover": { backgroundColor: colors.greenAccent[600] },
          }}
        >
          Search
        </Button>
      </Box>

      {dataFetched && (
        <>
          <ButtonGroup variant="contained" sx={{ mb: 2 }}>
            <Button
              onClick={() => handleViewModeChange("all")}
              sx={{
                backgroundColor: viewMode === "all" ? colors.greenAccent[500] : colors.greenAccent[600],
                color: colors.grey[100],
                "&:hover": { backgroundColor: colors.greenAccent[500] },
              }}
            >
              All Views
            </Button>
            <Button
              onClick={() => handleViewModeChange("daily")}
              sx={{
                backgroundColor: viewMode === "daily" ? colors.greenAccent[500] : colors.greenAccent[600],
                color: colors.grey[100],
                "&:hover": { backgroundColor: colors.greenAccent[500] },
              }}
            >
              Daily Info
            </Button>
          </ButtonGroup>

          {errorMessage ? (
            <Typography variant="h6" color="error" align="center">
              {errorMessage}
            </Typography>
          ) : (
            <BarChart
              dataset={chartData}
              xAxis={[{ scaleType: "band", dataKey: "screen", label: "Screens" }]}
              yAxis={[{ label: "Number of Visits" }]}
              series={[{ dataKey: "count", label: "Visits", valueFormatter }]}
              layout="vertical"
              {...chartSetting}
            />
          )}
        </>
      )}
    </Box>
  );
}

export default SearchUserUsage;
