import React, { useEffect, useState, useCallback } from "react";
import { tokens } from "../../Theme";
import { BarChart } from '@mui/x-charts/BarChart';
import { Button, Typography, Box, useTheme, ButtonGroup } from '@mui/material';
import { getProtocolAnalytics } from '../../api/helpers';

const chartSetting = {
  width: 1000,
  height: 500,
};

const valueFormatter = (value: number | null) => `${value}`;

export function ProtocolAnalytics() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([
        { protocol: 'nutrition', count: 0 },
        { protocol: 'recovery', count: 0 },
        { protocol: 'movement', count: 0 },
        { protocol: 'cognition', count: 0 },
        { protocol: 'connection', count: 0 },
        { protocol: 'aesthetics', count: 0 },
    ]);
    const [timeRange, setTimeRange] = useState('last_24_hours');

    const fetchProtocolCounts = useCallback(async () => {
        const protocolCounts = await getProtocolAnalytics(timeRange);

        if (protocolCounts) {
            setData([
                { protocol: 'nutrition', count: protocolCounts.nutrition },
                { protocol: 'recovery', count: protocolCounts.recovery },
                { protocol: 'movement', count: protocolCounts.movement },
                { protocol: 'cognition', count: protocolCounts.cognition },
                { protocol: 'connection', count: protocolCounts.connection },
                { protocol: 'aesthetics', count: protocolCounts.aesthetics },
            ]);
        }
    }, [timeRange]);

    useEffect(() => {
        fetchProtocolCounts();
    }, [timeRange, fetchProtocolCounts]);

    const handleButtonClick = (selectedTimeRange: string) => {
        setTimeRange(selectedTimeRange);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography variant="h3" align="center" gutterBottom>
                DailyInfoScreen Usage ({timeRange.replace('_', ' ').replace('_', ' ').toUpperCase()})
            </Typography>
            <ButtonGroup 
                variant="contained" 
                aria-label="outlined primary button group"
                sx={{ 
                    backgroundColor: theme.palette.success.main, 
                    color: theme.palette.grey[100], 
                    mt: 2, 
                    mb: 2 
                }}
            >
                <Button
                    onClick={() => handleButtonClick('last_24_hours')}
                    sx={{
                        backgroundColor: timeRange === 'last_24_hours' ? colors.greenAccent[500] : colors.greenAccent[600],
                        color: colors.grey[100],
                        '&:hover': {
                            backgroundColor: colors.greenAccent[500],
                        },
                    }}
                >
                    Last 24 Hours
                </Button>
                <Button
                    onClick={() => handleButtonClick('this_week')}
                    sx={{
                        backgroundColor: timeRange === 'this_week' ? colors.greenAccent[500] : colors.greenAccent[600],
                        color: colors.grey[100],
                        '&:hover': {
                            backgroundColor: colors.greenAccent[500],
                        },
                    }}
                >
                    This Week
                </Button>
                <Button
                    onClick={() => handleButtonClick('this_month')}
                    sx={{
                        backgroundColor: timeRange === 'this_month' ? colors.greenAccent[500] : colors.greenAccent[600],
                        color: colors.grey[100],
                        '&:hover': {
                            backgroundColor: colors.greenAccent[500],
                        },
                    }}
                >
                    This Month
                </Button>
            </ButtonGroup>
            <BarChart
                dataset={data}
                xAxis={[{ scaleType: 'band', dataKey: 'protocol', label: 'PROTOCOLS' }]}
                yAxis={[{ label: 'USERS' }]}
                series={[{ dataKey: 'count', label: 'USERS', valueFormatter }]}
                layout="vertical"
                {...chartSetting}
            />
        </Box>
    );
}

export default ProtocolAnalytics;